import React, { Component } from 'react';

export class EmailForm extends Component {
  render() {
    //const { message } = this.state;

    /*
            <iframe id='signup-form-iframe' width='540' height='305'
                src='https://913e9121.sibforms.com/serve/MUIEAF6TxHRJbE_iYAWq3gHgQZwRkK-FUxEdrXd-Xf7TV-xCGH3xWPf3RfnRLiOW9g49L0B5NNC2e4wGBMuzoJHbcpWx0s7id0vlgLdbTQVz4osZPIVY75eRYMzC9Buwp3Pnd7SZqUyHs0IFrpYYvBhOEYVjLdBKlk8VNiBqnyr3n80mFfkAN2ABq-B_i0PhUERAfinW7PmBTHsI'
                frameBorder='0' scrolling='auto' allowFullScreen
                title='signup form'
                /* style={{ display: 'block', 'margin-left': 'auto', 'margin-right': 'auto', 'max-width': '100%' }}></iframe>
 */

    return (
      <div id='signup-form'>
        <a href="https://913e9121.sibforms.com/serve/MUIEAOAbCC6LyIiV4ox8lzmQdWnHV0isOtmKLjKjwe3TPZ7p0irs6KT2zrH7A5KtL5Wkm0BxqDJrfXmC1SFpHgS8sNM61a23KMfr_0ndmWAPNFFPpdKGKqnArRa4jVJI40flG2UMh41G-uLB4W2ZQbweVtGWhGjVbtZ7eHp0jW-XIfXZZ6439SdugUUczdW11EHnVBuGUS8smfxz">Suscribir</a>
      </div>
    );
  }
}

export default EmailForm;
