module.exports = {
  siteTitle: 'paisas.tech', // <title>
  manifestName: 'paisas.tech',
  manifestShortName: 'paisas.tech', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'paisas.tech',
  subHeading:
    "Un podcast sobre tecnologia. De Antioquia para Antioquia.",
  socialLinks: [
/*
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/anubhavsrivastava',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/onlyanubhav',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/theanubhav',
    },
 */
    {
      icon: 'fa-podcast',
      name: 'Anchor',
      url: 'https://anchor.fm/paisastech'
    },
    {
      icon: 'fa-rss',
      name: 'Anchor',
      url: 'https://anchor.fm/s/53778974/podcast/rss'
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:paisas@paisas.tech',
    },
  ],
};
